<template>
  <v-card>
    <v-card-title>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="talentHeaders"
          :items="talentData"
          :loading="loading"
          :search="search"
      >
        <template v-slot:item.expertise="{ item }">
          <v-chip
              v-for="(val, i) in formatSkillsExperience(item.json.experiences)"
              :key="i"
              color="primary"
              pill
              small
          >
            {{val.skill}}
          </v-chip>
        </template>
        <template v-slot:item.skill_test_score="{ item }">
          {{item.skill_test_skill}}:{{item.skill_test_score}}
        </template>
        <template v-slot:item.moreinfo="{ item }">
          <v-btn icon @click="setJsonViewerItem(item)"><v-icon>mdi-book-information-variant</v-icon></v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <TableViewJsonSelectedRowColumnDialog v-if="jsonViewerDialog" :dialog="jsonViewerDialog" :json="jsonViewerItem" @TableViewSelectedRowColumnDialogClosed="toggleJsonViewerDialog"></TableViewJsonSelectedRowColumnDialog>
  </v-card>
</template>

<script>
import axios from "axios";
import TableViewJsonSelectedRowColumnDialog from "@/components/tables/dialogues/TableViewJsonSelectedRowColumnDialog";

export default {
  name: "TableNetworkView",
  components: {TableViewJsonSelectedRowColumnDialog},
  mounted() {
    this.getFromEndpoint()
  },

  data() {
    return {
      talentData: [],
      talentHeaders: [
        { text: "Name", value: "name" },
        { text: "Expertise", value: "expertise" },
        { text: "/dev Score", value: "slashdev_score" },
        { text: "ST Score", value: "skill_test_score" },
        { text: "English Score", value: "english_level" },
        { text: "Labels", value: "labels" },
        { text: "Notes", value: "notes" },
        { text: "Stage", value: "stage" },
        { text: "More Info", value: "moreinfo" },
      ],
      loading: true,
      saving: false,
      search: "",
      jsonViewerDialog: false,
      jsonViewerItem: null,
    }
  },

  methods: {
    setJsonViewerItem(item) {
      this.jsonViewerItem = item
      this.toggleJsonViewerDialog()
    },

    toggleJsonViewerDialog() {
      this.jsonViewerDialog = !this.jsonViewerDialog
    },

    formatSkillsExperience(item) {
      let arr = []

      if (!item)
        return arr

      for (let [key, value] of Object.entries(item)) {
        if (value.level === "expert" || value.level === "professional") {
          if (value.years >= 3) {
            arr.push({
              skill: key,
              level: value.level
            })
          }
        }
      }

      return arr
    },

    getFromEndpoint() {
      let self = this
      self.loading = true

      axios.get(`${this.$store.state.apiUrl}/api/talent-network`, this.$store.state.headers)
          .then(function (response) {
            self.talentData = response.data
            self.loading = false
          });
    },

  }
}
</script>

<style scoped>

</style>
